import toastr from 'toastr';

export default class ContentRenderer {
  constructor(xhr, callback, contentTarget, outer) {
    this.xhr = xhr;
    this.callback = callback;
    this.contentTarget = contentTarget;
    this.outer = outer;
  }

  renderError() {
    this.render({ success: false });
  }

  render(options) {
    if (!this.xhr.response) return;

    if (this.xhr.response[0] == '{') {
      let data = JSON.parse(this.xhr.response);
      if (data.message) {
        if (data.success) {
          toastr.success(data.message);
        } else {
          toastr.error(data.message);
        }
      }
      if (this.callback) {
        this.callback(data);
      }
    } else {
      if (options && options.success == false) {
        if (this.callback) {
          this.callback({ success: false });
        }
        toastr.error(this.xhr.responseText);
      } else {
        this.showContent(this.xhr.response);
        if (this.callback) {
          this.callback({ success: true });
        }
      }
    }
  }

  showContent(content) {
    if (this.contentTarget) {
      const htmlTarget = this.outer ? 'outerHTML' : 'innerHTML';
      if (typeof this.contentTarget === 'string') {
        if (htmlTarget == 'innerHTML') {
          this.setInnerHTML(document.querySelector(this.contentTarget), content);
        } else {
          document.querySelector(this.contentTarget)[htmlTarget] = content;
        }
      } else {
        this.contentTarget[htmlTarget] = content;
      }
    } else {
      toastr.success(content);
    }
  }

  setInnerHTML(el, html) {
    el.innerHTML = html;

    Array.from(el.querySelectorAll("script"))
      .forEach( oldScriptEl => {
        const newScriptEl = document.createElement("script");

        Array.from(oldScriptEl.attributes).forEach( attr => {
          newScriptEl.setAttribute(attr.name, attr.value)
        });

        const scriptText = document.createTextNode(oldScriptEl.innerHTML);
        newScriptEl.appendChild(scriptText);

        oldScriptEl.parentNode.replaceChild(newScriptEl, oldScriptEl);
    });
  }
}
