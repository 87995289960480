export default class TurboHttpRequest {
  constructor(url, method) {
    this.url = url;
    this.method = method || 'GET';
  }

  execute() {
    const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    const formData = new FormData(this.form)
    formData.append('authenticity_token', csrfToken);
    fetch(this.url, {
      headers: { Accept: "text/vnd.turbo-stream.html" },
      method: this.method,
      body: formData
    }).then(r => r.text())
      .then(html => Turbo.renderStreamMessage(html));
  }
}
