import $ from 'jquery';
import { Controller } from 'stimulus';
import TurboHttpRequest from '../../helpers/turbo_http_request';

export default class extends Controller {
  static targets = ['select']

  connect() {
    $(this.selectTarget).on('select2:select', this.itemSelected.bind(this));
  }

  itemSelected(e) {
    const { id } = e.params.data;
    const baseUrl = this.element.dataset.onItemSelectedUrl;
    const url = `${baseUrl}${baseUrl.includes('?') ? '&' : '?'}stock_id=${id}`;
    const turboHttpRequest = new TurboHttpRequest(url, 'POST');
    turboHttpRequest.execute();
  }
}
