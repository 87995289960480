import { Controller } from 'stimulus';
import TurboHttpRequest from '../../helpers/turbo_http_request';

export default class extends Controller {
  fetch(e) {
    e.preventDefault();
    e.stopPropagation();

    const baseUrl = this.element.dataset.url;
    const query = (!this.element.name || this.element.name == '') ? null : `${this.element.name}=${this.element.value}`;
    const url = query ? (`${baseUrl}${baseUrl.includes('?') ? '&' : '?'}${query}`) : baseUrl;

    const turboHttpRequest = new TurboHttpRequest(url, this.element.dataset.method);
    turboHttpRequest.execute();
  }
}